import fitnessTraining1 from "../assets/home4.jpeg";
import fitnessTraining2 from "../assets/home3.png";
import fitnessTraining3 from "../assets/home2.jpeg";
import FitnessTraining4 from "../assets/nutrition.jpeg";
import fitnessTraining5 from "../assets/femalenutrition.jpeg";
import injuryPrevention from "../assets/injuryprevention.jpeg";
import ketoSpecialization from "../assets/ketospecialization.jpeg";
import intermitent from "../assets/intermitent.jpeg";
import functional from "../assets/functional.jpeg";
import abhishekarya from "../assets/Abhishekarya.jpg";
import kinesiology from "../assets/kinesiology.jpeg";
import anabolics from "../assets/anabolic.jpeg";
import abuzar from "../assets/abuzar.jpg";
import khushboo from "../assets/khushboo.jpg";
import personavatar from "../assets/personavatar.png"


// Home Swiper Images
import homeSwiperImh1 from "../assets/s1.jpg";
import homeSwiperImh2 from "../assets/s2.jpg";
import rishabh from "../assets/rishabh-cpt.jpeg";

// Trainers Data Images
import trainer1 from "../assets/tr2.png";
import trainer2 from "../assets/tr1.png";
import trainer3 from "../assets/tr3.png";

// The Story Behind Our Gym imege
import story from "../assets/about5.png";

// allOurTrainersData Images
import ourTrainer1 from "../assets/home4.jpeg";
import ourTrainer2 from "../assets/home2.jpeg";
import ourTrainer3 from "../assets/home3.png";
import ourTrainer4 from "../assets/classes2.png";
import ourTrainer5 from "../assets/classes3.png";
import ourTrainer6 from "../assets/classes4.png";

import ourTrainers1 from "../assets/tr1.png";
import ourTrainers2 from "../assets/tr2.png";
import ourTrainers3 from "../assets/tr3.png";
import ourTrainers4 from "../assets/trainers2.png";
import ourTrainers5 from "../assets/trainers3.png";
import ourTrainers6 from "../assets/trainers4.png";

import blogArticle1 from "../assets/blog2.png";
import blogArticle2 from "../assets/blog3.png";
import blogArticle3 from "../assets/blog4.png";
import blogArticle4 from "../assets/blog5.png";
import blogArticle5 from "../assets/blog6.png";
import blogArticle6 from "../assets/blog7.png";

// Small Images
import thumbnail1 from "../assets/blog8.png";
import thumbnail2 from "../assets/blog9.png";
import thumbnail3 from "../assets/blog10.png";
import thumbnail4 from "../assets/blog11.png";
import thumbnail5 from "../assets/blog12.png";
import thumbnail6 from "../assets/blog13.png";

export const headerLinksData = [
  {
    id: 1,
    title: "Home",
    to: "/",
  },
  {
    id: 2,
    title: "About",
    to: "/about",
  },
  {
    id: 3,
    title: "Courses",
    to: "/classes",
  },
  {
    id: 4,
    title: "Faculty",
    to: "/trainers",
  },
  {
    id: 5,
    title: "Blog",
    to: "/blog",
  },
  {
    id: 6,
    title: "Contact",
    to: "/contact",
  },
];

export const fitnessTrainingData = [
  {
    id: 1,
    title: "Certified Personal Trainer",
    description:
      "A certified personal trainer (CPT) is a fitness professional who has obtained certification from a recognized organization, demonstrating their knowledge and expertise in designing and delivering effective fitness programs tailored to the individual needs of their clients. ",
    image: fitnessTraining3,
    to: "/yogaClass",
    price:38000
  },

  {
    id: 2,
    title: "Certified Sports Nutritionist",
    description:
      "A Certified Sports Nutritionist is a professional who specializes in the dietary and nutritional needs of athletes and active individuals. They provide tailored nutritional advice and strategies to enhance athletic performance, support recovery, and promote overall health. ",
    image: FitnessTraining4,
    to: "/",
    price:35000
  },

  {
    id: 3,
    title: "Certificate in Female Fitness",
    description:
      "A Certificate in Female Fitness is a specialized program designed to equip fitness professionals with the knowledge and skills needed to address the unique fitness and health needs of women. This certification focuses on various aspects of female physiology, fitness programming, and wellness strategies tailored specifically for women.",
    image: fitnessTraining5,
    to: "/",
    price:15000
  },
  {
    id: 4,
    title: "Special Population specialist",
    description:
      "A Special Population Specialist is a fitness professional who has specialized training and expertise in designing and implementing exercise programs for individuals with specific health conditions, disabilities, or other unique needs. These specialists work with clients who may have chronic diseases, physical limitations, or other health concerns that require tailored fitness approaches. ",
    image: fitnessTraining1,
    to: "/",
    price:20000
  },
];

export const homeSwiperData = [
  {
    id: 1,
    name: "Rishabh Ramchandani",
    title: "Student - Certified Personal Trainer Course",
    image: rishabh,
    description: `Hi, my name is Rishabh Ramchandani student of EFA CPT batch - 1, I was always interested to study the mechanics of how people do transformations and was eager to learn about all the things related to being a personal trainer. And then surely found out about EFA and from day 1 the faculty helped me clear all my myths and taught everything that helped me become a Certified fitness trainer.`,
  },

  {
    id: 2,
    name: "Udit Narayan Sahu",
    title: "Student - Certified Personal Trainer Course, Nutrition",
    image: personavatar,
    description: `Evolve Fit Academy exceeded my expectations in both the Certified Personal Trainer and Sports Nutrition courses. The quality of training and teaching was exceptional, with instructors who are not only experts in their fields but also deeply passionate about helping students succeed. The courses were well-structured, combining theory and hands-on practice, which gave me the confidence and skills needed to excel in the fitness industry. The academy's supportive environment and commitment to student success truly set it apart. I highly recommend Evolve Fit Academy to anyone serious about pursuing a career in fitness and nutrition.`,
  },

  {
    id: 3,
    name: "Punit Mandalwar",
    title: "Student - Certified Personal Trainer Course",
    image: personavatar,

    description: `Evolve Fit Academy provided outstanding training in the Certified Personal Trainer course. The instructors are highly knowledgeable and passionate, making complex concepts easy to understand. The course was very well organised covering many concepts from different academies. The supportive environment and hands-on approach ensured I gained the necessary skills to excel in my career. I feel fully prepared to succeed as a personal trainer, thanks to the excellent education at Evolve Fit Academy. I highly recommend this course to anyone looking to enter the fitness industry.`,
  },

  {
    id: 4,
    name: "Sandeep Sengar",
    title: "Student - Certified Personal Trainer Course, Nutrition",
    image: personavatar,
    description: `Hi I am Sandeep Sengar from Indore. I Completed my Personalk Trainer Certification from Evolve Fit Academy. This institute made getting my personal trainer Certification easy and enjoyable. The teachers Dr. Khushbu Chhaparwal mam and Abhishek Arya Sir were knowledeable and Supportive, guiding me through every Step of the process. The Curriculum was wellorgan ized, Covering everything from Exercise Science to Client Communication. With hands-on training and practical experience, I felt prepared to start Seoulas my Career as soon as I finished the program. I highly recommend Evolve fit Academy to anyont looking to become a Certified Personal

trainer. Thank to Dr.Khushbu Chhaparwal mam and Abhishek Arya Sir`,
  },
  {
    id: 5,
    name: "Sameer Rathore",
    title: "Student - Certified Personal Trainer Course, Nutrition",
    image: personavatar,
    description: `There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour`,
  },
];

export const trainersData = [
  {
    id: 1,
    nam: "Dr. Khushbu Chhaparwal",
    title: "Fitness & Nutrition Trainer",
    image: khushboo,
    to: "https://www.linkedin.com/in/drkhushbuchhaparwal/",
  },

  {
    id: 2,
    nam: "Abhishek Arya",
    title: "Practical Fitness Trainer",
    image: abhishekarya,
    to: "https://www.linkedin.com/in/the-mustafa-khaled/",
  },

  {
    id: 3,
    nam: "Sanjay Pandey",
    title: "Yoga Trainer",
    image: personavatar
    ,
    to: "https://www.linkedin.com/in/the-mustafa-khaled/",
  },
];

export const gymAmenitiesData = [
  {
    id: 1,
    title: "Doctor Faculty",
    description: "Learn from Doctor Faculty with more than 18yrs experience and more than 30 National & International Certifications.",
  },
  {
    id: 2,
    title: "Skill India SPEFL-SC accredited",
    description: `   We are proudly associated with the Spefl-SC, Skill India Department of Government of India. Our Courses are Accredited with Spefl-SC Skill India Council,
      `,
  },
  {
    id: 4,
    title: "150+ Video Exercise Library",
    description:
      "200 + learning hours for your reference with colored manual and On-Demand Recorded Live Lectures available. Online/Offline mode also avaialble for distance learning.",
  },
  {
    id: 7,
    title: "Fully equipped International Gym Facility for practice",
    description: `We give Hands on practice to students in world class facility like Gold's Gym which has fully equipped cardio, strength training, group exercise , functional training facilities.
              `,
  },
  {
    id: 10,
    title: "100% Placement Assistance",
    description: `With our vast connections we provide 100% assistance with placements to students at gyms like Anytimefitness, Gerai, Talwalkars, Cult Fit etc. `,
  },
  {
    id: 11,
    title: "Community for Lifetime Support",
    description: `We understand thet doubts arise once students are in real life situation and to help and clear their doubts we have acommunity to discuss doubts, new updates etc.`,
  },
  {
    id: 12,
    title: "Easy EMI Facility available",
    description: `We provide EMI facility to students to make their learning journey easier and ongoing.`,
  },
];

export const ourPlansData = [
  {
    id: 1,
    title: "Certified Personal Trainer",
    offer: "INR 38000",
    description: "10% Off for one-time payment",
    icon: "fa-solid fa-heart",
    pointers: [
      "3.5 months Duration",
      "2 days a week classes",
      "Practical sessions included",
      "International valid CPR and AED Certificate",
      "Complimentary 2 day Yoga Workshop",
      "Level-4 Skill India Approved Certificate",
    ],
  },
  {
    id: 2,
    title: "Certified Sports Nutrition",
    offer: "INR 35000",
    description: "Highly Recommended",
    icon: "fa-solid fa-star",
    pointers: [
      "3 months Duration",
      "2 days a week classes",
      "Level 6 Skill India approved certificate",
    ],
  },

  {
    id: 3,
    title: "Female Fitness Specialist",
    offer: "INR 15000",
    description: "10% Off for Females",
    icon: "fa-solid fa-gem",
    pointers: ["1.5 months Duration", "2 days a week classes", "EFA Certified"],
  },
  {
    id: 3,
    title: "Special Population Specialist",
    offer: "INR 20000",
    description: "10% Off for ex-students",
    icon: "fa-solid fa-gem",
    pointers: ["2 months Duration", "2 days a week classes", "EFA Certified"],
  },
  {
    id: 2,
    title: "Injury Prevention Specialist",
    offer: "INR 25000",
    description: "Expertise on Post Injury workouts",
    icon: "fa-solid fa-star",
    pointers: ["3 months Duration", "2 days a week classes", "EFA Certified","10% Off for ex-students"],
  },
];

export const articlesNewsData = [
  {
    id: 1,
    about: "Fitness",
  },

  {
    id: 2,
    about: "Health",
  },

  {
    id: 3,
    about: "Nutrition",
  },
];

export const gymStoryData = [
  {
    id: 1,
    welcome: "Welcome",
    title: "Affiliations and Accreditation",
    storyData:
      "Recognizing the challenges trainers face in traveling frequently for education and the associated financial burden, I decided to establish EVOLVE FIT ACADEMY. This initiative aims to provide comprehensive knowledge to trainers in a convenient and accessible manner.",
  },

  {
    id: 2,
    title: "Proudly Affiliated to",
    image: "https://sportsskills.in/images/main%20logos/spefl_header_logo.png",
    storyData: "",
    rlss: "https://www.lifesavingindia.org/wp-content/uploads/2020/03/RLSS_logo_2x2.png",
  },
  {
    id: 3,
    title: "Our Mission",
    data: `Our mission is to deliver the highest quality and research-based education in the realm of fitness and sports sciences. 
    In pursuit of this mission, EVOLVE FIT ACADEMY:
•⁠  ⁠Educates and trains instructors to meet global standards.
•⁠  ⁠Evaluates, certifies, and facilitates the employment of trainees.
•⁠  ⁠Cultivates a sustainable organization through ongoing innovation.
`,
  },

  {
    id: 4,
    title: "Our Value",
    data: `These values form the cornerstone of our operations:

•⁠  ⁠Respect for the community.
•⁠  ⁠Passion for excellence.
•⁠  ⁠Building relationships based on mutual trust.
•⁠  ⁠Upholding the highest ethical and moral standards.
•⁠  ⁠Recognizing efforts and achievements.
•⁠  ⁠Commitment to research and innovation.
•⁠  ⁠Striving for customer delight.`,
  },
];

export const valuesBoxes = [
  {
    id: 1,
    icon: "fa-regular fa-newspaper",
    title: "Certified trainer",
  },
  {
    id: 2,
    icon: "fa-solid fa-apple-whole",
    title: "Nutrition & diet",
  },

  {
    id: 3,
    icon: "fa-solid fa-person",
    title: "Years of experience",
  },
];

export const valuesNumbers = [
  {
    id: 1,
    number: "20+",
    description: "Year of Experience",
  },

  {
    id: 2,
    number: "500+",
    description: "Students Placed",
  },

  {
    id: 3,
    number: "50+",
    description: "Expert Trainers",
  },

  {
    id: 4,
    number: "7k+",
    description: "Instagram followers",
  },
];

export const allOurTrainersData = [
  ...fitnessTrainingData,
  {
    id: 5,
    title: "Injury Prevention Specialist",
    description:
      "An Injury Prevention Specialist is a fitness professional focused on identifying risks and implementing strategies to prevent injuries in various populations, including athletes, recreational exercisers, and individuals with specific health concerns. These specialists apply their expertise in biomechanics, exercise science, and risk management to create safe exercise environments and practices.",
    image: injuryPrevention ,
    to: "/yogaClass",
    price:25000

  },
];
export const worksShopsData = [
  {
    id: 1,
    title: "Keto Specialization",
    description:
      " Keto Specialization certification is designed for health and fitness professionals who want to gain in-depth knowledge and expertise in the ketogenic diet and its application for various health and fitness goals. This specialization enables professionals to guide clients through the process of adopting and maintaining a ketogenic lifestyle safely and effectively.",
    image: ketoSpecialization,
    to: "/",
    price:1500
  },
  {
    id: 2,
    title: "Science of Intermittent Fasting",
    description:
      " Intermittent fasting (IF) is an eating pattern that cycles between periods of fasting and eating. It's not so much about what you eat, but when you eat. Intermittent fasting has gained popularity for its potential health benefits, including weight loss, improved metabolic health, and longevity. ",
    image: intermitent,
    to: "/",
    price:1500
  },
  {
    id: 3,
    title: "Anabolic Awareness",
    description:
      " Anabolic steroid awareness involves understanding the use, effects, risks, and ethical considerations of anabolic steroids, which are synthetic derivatives of the male hormone testosterone. This knowledge is crucial for athletes, coaches, fitness professionals, and healthcare providers to promote safe and ethical practices in sports and fitness.",
    image: anabolics,
    to: "/",
    price:5000
  },
  {
    id: 4,
    title: "Functional Training",
    description:
      " Functional training is a fitness approach that emphasizes exercises designed to mimic and enhance the movements used in everyday life or specific activities. The goal is to improve overall strength, balance, flexibility, and coordination, making daily tasks easier and reducing the risk of injury. This type of training is beneficial for athletes, fitness enthusiasts, and individuals of all fitness levels, particularly those recovering from injuries or looking to improve their functional capacity in daily activities",
    image: functional,
    to: "/",
    price:8000
  },
  {
    id: 5,
    title: "Kinesiology Taping",
    description:
      " Kinesiology taping, also known as kinesio taping, is a therapeutic technique used to support muscles and joints without restricting their range of motion. It involves applying a special elastic tape in specific patterns to provide various benefits, such as pain relief, improved circulation, and enhanced performance. This technique is widely used by athletes, physical therapists, chiropractors, and other healthcare professionals.",
    image: kinesiology,
    to: "/",
    price:5000
  },
];
export const ourTrainers = [
  {
    id: 1,
    image: khushboo,
    name: "Dr. Khushbu Chhaparwal",
    description:
      "Finished graduation in Physiotherapy in 2003 with Distinction and Sixth position in the university. Practised Physiotherapy for 2 years along with teaching Physiotherapy students. With inclination towards fitness worked with national brand Talwalkars. Got the opportunity to connect to Gold's Gym and worked as General Manager for 2 branches in Indore for 5 yrs. While throughout the time self-updating continued resulting in vast knowledge on various aspects of fitness along with many seminars and teaching trainers at various gyms. Now with an experience of 18+ yrs in the industry. ",
  },

  {
    id: 2,
    image: abhishekarya,
    name: "Abhishek Arya",
    description:
      "Certified Fitness Trainer from K11 Fitness Academy, Graduate in B.com from Renaissance College of Commerce and Management, Indore. Working in Fitness Industry for over 8 years in Mumbai and Indore. Also worked as a fashion model and done fashion shows across the country for many renowned Fashion Brands and Designers. Previously worked in Talwalkars Gym Indore, as a Personal Trainer and delivered goal oriented transformations to the clients for around 2 years. And currently working in Gold's Gym Vijaynagar Indore as The Floor Manager for last 1 year. And designing personalized workout plans for over 700 active members.",
  },

  {
    id: 3,
    image: personavatar,
    name: "Sanjay Pandey",
    description:
      "Working with Gold's Gym since 5 years. 3 year experience in Talwalkar Gym. 4 years experience in pink flower h. 5. School as a yoga teacher # at present working with pioneer convent school as a yoga teacher.",
  },
  {
    id: 3,
    image: abuzar,
    name: "Abuzar Irani - Guest Faculty",
    description:
      "He is a Strength training and Bodybuilding Expert also a competitive Athlete. He has an experience of more than 10yrs. of training. He is associated with a lots of companies as their Percent Brand Ambassador like Evolution Sports Nutrition, Protein Planet, Bio Fit, and recently with Being Strong. Presently competing as Professional Classic Physique Athlete and Fitness Model with various companies.",
  },
];

export const blogArticlesNewsData = [
  {
    id: 1,
    title: "The 10 best exercises to do in your park",
    name: "Jacob Cornish",
    image: blogArticle1,
    thumbnail: thumbnail1,
  },
  {
    id: 2,
    title: "How to Choose The Right Equipment For You",
    name: "Benjamin Gray",
    image: blogArticle2,
    thumbnail: thumbnail2,
  },
  {
    id: 3,
    title: "How to Maximum Time Spent at the Gym",
    name: "David Ferguson",
    image: blogArticle3,
    thumbnail: thumbnail3,
  },

  {
    id: 4,
    title: "Simple Condition for all Around Fitness.",
    name: "William Wilkins",
    image: blogArticle4,
    thumbnail: thumbnail4,
  },

  {
    id: 5,
    title: "How to Modify any Program to Improve Your Weakness",
    name: "Benjamin Gray",
    image: blogArticle5,
    thumbnail: thumbnail5,
  },

  {
    id: 6,
    title: `The Beginner’s Guide to Weight Lifting`,
    name: "Bernadette",
    image: blogArticle6,
    thumbnail: thumbnail6,
  },
];

export const policyData = { privacy : `Privacy Policy

Effective Date: 07-08-2024

Evolve Fit Academy ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website, mobile application, and any related services (collectively, the "Services").

By using our Services, you agree to the collection and use of information in accordance with this Privacy Policy. If you do not agree with this policy, please do not use our Services.

### 1. Information We Collect

#### a. Personal Information
We may collect personal information that you provide directly to us, including but not limited to:
•⁠  ⁠Name
•⁠  ⁠Email address
•⁠  ⁠Phone number
•⁠  ⁠Payment information
•⁠  ⁠Fitness goals and preferences
•⁠  ⁠User-generated content (e.g., profile pictures, progress photos)

#### b. Usage Data
We collect information about your interactions with our Services, including:
•⁠  ⁠Device information (e.g., IP address, browser type)
•⁠  ⁠Log data (e.g., access times, pages viewed)
•⁠  ⁠Usage patterns (e.g., workouts completed, progress tracked)
•⁠  ⁠Location data (if enabled on your device)

#### c. Cookies and Tracking Technologies
We use cookies and similar technologies to collect information about your use of our Services and to enhance your user experience. You can control cookie settings through your browser.

### 2. How We Use Your Information

We use the information we collect to:
•⁠  ⁠Provide, maintain, and improve our Services
•⁠  ⁠Personalize your experience based on your fitness goals and preferences
•⁠  ⁠Process payments and manage your account
•⁠  ⁠Send you updates, newsletters, and promotional materials
•⁠  ⁠Monitor and analyze usage patterns to improve our Services
•⁠  ⁠Respond to your inquiries and provide customer support
•⁠  ⁠Ensure the security of our Services and prevent fraud

### 3. How We Share Your Information

We may share your information with:
•⁠  ⁠Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and customer support.
•⁠  ⁠Business Transfers: In the event of a merger, acquisition, or sale of assets, your information may be transferred as part of the transaction.
•⁠  ⁠Legal Requirements: We may disclose your information if required by law or in response to valid requests by public authorities (e.g., court orders or government agencies).
•⁠  ⁠With Your Consent: We may share your information with third parties if you provide explicit consent.

### 4. Data Security

We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, please be aware that no method of transmission over the internet or electronic storage is completely secure.

### 5. Your Rights and Choices

You have the following rights regarding your personal information:
•⁠  ⁠Access: You can request a copy of the personal information we hold about you.
•⁠  ⁠Correction: You can request that we correct any inaccurate or incomplete information.
•⁠  ⁠Deletion: You can request that we delete your personal information, subject to certain legal exceptions.
•⁠  ⁠Opt-Out: You can opt-out of receiving promotional communications from us by following the unsubscribe instructions in those communications.

### 6. Third-Party Links

Our Services may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third-party sites. We encourage you to review the privacy policies of those third parties.

### 7. Children's Privacy

Our Services are not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected such information, we will take steps to delete it.

### 8. Changes to This Privacy Policy

We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website and updating the "Effective Date" above. We encourage you to review this policy periodically to stay informed about how we are protecting your information.

### 9. Contact Us

If you have any questions about this Privacy Policy or our privacy practices, please contact us at:

Evolve Fit academy  
OR-422, Indus Satellite greens, Dewas Naka ,Indore 
evolvefitacademy2020@gmail.com 
+918770155279`,
refund: `Last Updated: 07-08-2024

At Evolve Fit Academy, we are committed to providing quality educational content and services to help you achieve your fitness goals. We understand that sometimes circumstances change, and you may need to request a refund. This Refund Policy outlines the conditions under which refunds will be granted and the process for requesting a refund.

1. Subscription Plans

•⁠  ⁠Monthly Subscriptions: Monthly subscriptions are non-refundable. If you cancel your monthly subscription, you will continue to have access to the Services until the end of the current billing cycle. No refunds will be issued for partial months of service.

•⁠  ⁠Annual Subscriptions: Annual subscriptions may be eligible for a partial refund if canceled within the first 14 days of the subscription period. If you cancel after 14 days, no refund will be provided, but you will retain access to the Services until the end of the subscription year.

2. One-Time Purchases

•⁠  ⁠Courses and Programs: Refunds for one-time purchases of courses or programs are generally not available. However, if you believe you have purchased a course or program in error, please contact us within 3 days of purchase, and we will review your request on a case-by-case basis.

•⁠  ⁠Digital Products: All sales of digital products (e.g., eBooks, downloadable guides) are final and non-refundable.

3. Special Circumstances

•⁠  ⁠Technical Issues: If you experience technical issues that prevent you from accessing our Services and we are unable to resolve the issue, you may be eligible for a partial or full refund, depending on the nature of the issue and the time elapsed since the purchase. Please contact our support team for assistance.

•⁠  ⁠Medical Reasons: If you are unable to continue using our Services due to a medical condition, we may offer a partial refund or pause your subscription. A medical certificate or other supportive legitimate documentation may be required which will be crosschecked by our inhouse doctors before passing any refunds.

4. Refund Process

•⁠  ⁠Requesting a Refund: To request a refund, please contact our customer support team at Evolve Fit Academy with your order details and the reason for your refund request. Refund requests must be submitted within the applicable time frame outlined in this policy.

•⁠  ⁠Processing Refunds: Refunds will be credited within 14 business days of approval to the original payment method. If you paid by credit or debit card, please allow additional time for your bank to process the refund.

5. Non-Refundable Situations

Refunds will not be granted in the following situations:

•⁠  ⁠Failure to cancel a subscription before the renewal date.
•⁠  ⁠Dissatisfaction with the content after substantial use.
•⁠  ⁠Requests made outside of the applicable refund periods.
•⁠  ⁠Abuse of our refund policy (e.g., excessive refund requests).

6. Amendments to This Refund Policy

We may update this Refund Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on our website, and the "Last Updated" date will be revised accordingly.

7. Contact Us

If you have any questions or concerns about this Refund Policy, please contact us at:

Evolve Fit Academy
evolvefitacademy2020@gmail.com 
+918770155279 
OR-422, Indus Satellite Greens, Dewas Naka, Indore. M.P.`,
terms : `Terms and Conditions

Last Updated: 07-08-2024

1. Introduction

Welcome to Evolve Fit Academy (“we,” “us,” “our”). These Terms and Conditions (“Terms”) govern your use of our website, mobile application, and services (collectively, the “Services”). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree with these Terms, you should not use our Services.

2. Eligibility

To use our Services, you must be at least 18 years old or have reached the age of majority in your jurisdiction. By using the Services, you represent and warrant that you meet these eligibility requirements.

3. Account Registration

To access certain features of the Services, you may be required to create an account. You agree to provide accurate and complete information during the registration process and to keep your account information updated. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.

4. Use of Services

•⁠  ⁠Personal Use Only: Our Services are intended for personal, non-commercial use only. You agree not to use the Services for any commercial purposes without our prior written consent.

•⁠  ⁠Prohibited Activities: You agree not to:
  - Use the Services in any way that violates any applicable law or regulation.
  - Engage in any conduct that is harmful, threatening, or abusive.
  - Attempt to gain unauthorized access to our systems or any portion of the Services.
  - Distribute, copy, or modify any content available on the Services without our express permission.

5. Subscription and Payment

•⁠  ⁠Subscription Plans: We offer various subscription plans with different levels of access and features. Details of these plans, including pricing and payment terms, are available on our website.

•⁠  ⁠Billing: By subscribing to a plan, you authorize us to charge your payment method on a recurring basis according to the terms of your chosen subscription plan. All payments are non-refundable unless otherwise stated in our refund policy.

•⁠  ⁠Cancellation: You may cancel your subscription at any time by following the instructions on our website. Upon cancellation, your access to the Services will continue until the end of your current billing period.

6. Intellectual Property

•⁠  ⁠Ownership: All content, trademarks, logos, and other intellectual property available through the Services are the property of Evolve Fit Academy or our licensors. You may not use any of our intellectual property without our prior written consent.

•⁠  ⁠User Content: By submitting content through the Services, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, and distribute your content in connection with the Services.

7. Disclaimer of Warranties

Our Services are provided on an "as-is" and "as-available" basis. We do not warrant that the Services will be uninterrupted or error-free. To the fullest extent permitted by law, we disclaim all warranties, whether express or implied, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.

8. Limitation of Liability

To the fullest extent permitted by law, Evolve Fit Academy shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising from your use of the Services.

9. Indemnification

You agree to indemnify and hold Evolve Fit Academy and its affiliates, officers, agents, and employees harmless from any claims, liabilities, damages, losses, and expenses, including reasonable attorneys' fees, arising out of or in any way connected with your use of the Services or your violation of these Terms.

10. Changes to These Terms

We may modify these Terms from time to time. If we make material changes, we will provide you with notice by posting the updated Terms on our website or through other means of communication. Your continued use of the Services after the changes take effect constitutes your acceptance of the revised Terms.

11. Governing Law

These Terms shall be governed by and construed in accordance with the laws of Indore, without regard to its conflict of law principles.

12. Contact Information

If you have any questions or concerns about these Terms, please contact us at evolvefitacademy2020@gmail.com.

13. Entire Agreement

These Terms constitute the entire agreement between you and Evolve Fit Academy regarding the use of the Services and supersede any prior agreements or understandings, whether written or oral.

---

Disclaimer: This template is for informational purposes only and does not constitute legal advice. Please consult a legal professional to ensure that your Terms and Conditions are fully compliant with applicable laws and regulations.`}

export const policyMap = {
  privacy: "Privacy Policy",
  refund: "Refund Policy",
  terms: "Terms and Conditions",
};
export const contactInfoData = [
  {
    id: 1,
    title: "Phone",
    icon: "fa-solid fa-phone",
    cta: "+91 8770155279",
  },
  {
    id: 2,
    title: "Email",
    icon: "fa-solid fa-envelope",
    cta: "evolvefitacademy2020@gmail.com",
  },

  {
    id: 3,
    title: "Location",
    icon: "fa-solid fa-location-dot",
    cta: "Indore , India.",
  },
];
