import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import "./style.css"
import FitnessTraining4 from "../../assets/nutrition.jpeg";
import { CartContext } from '../../context/context';

const Cart = ({ cartItems=[{
    id: 2,
    title: "Certified Sports Nutritionist",
    description:
      "A Certified Sports Nutritionist is a professional who specializes in the dietary and nutritional needs of athletes and active individuals. They provide tailored nutritional advice and strategies to enhance athletic performance, support recovery, and promote overall health. ",
    image: FitnessTraining4,
    to: "/",
    price:35000
  }], handleRemove=(()=>{}) }) => {
    const globalState = useContext(CartContext);
    const {state,dispatch} = globalState;
  return (
    <div className="cart-container">
      <h2 className="title">Your Cart</h2>
      {state.length === 0 ? (
       <h3>Cart seems to be empty add a course from <a href='/classes'>here</a></h3>
      ) : (
        <>
          {state.length>0 && state.map((item, index) => (
            <div className="cart-item" key={index}>
              <div className="item-info">
                <h3>{item.name}</h3>
                <p>{item.title}</p>
              </div>
              <div className="item-price">Rs. {item.price}</div>
              <button className="remove-button" onClick={() => dispatch({type:"REMOVE_FROM_CART",payload:item})}>Remove</button>
            </div>
          ))}
          <Link to="/checkout" className="checkout-button">
            Proceed to Checkout
          </Link>
        </>
      )}
    </div>
  );
};

export default Cart;
