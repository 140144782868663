import React from 'react';
import { useForm } from 'react-hook-form';
import "./style.css";

const Checkout = ({ onSubmit }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  return (
    <div className="checkout-container">
      <h2 className="title">Checkout</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="checkout-form">
        <div className="form-group">
          <label>Name</label>
          <input {...register('name', { required: 'Name is required' })} />
          <p className="error-message">{errors.name?.message}</p>
        </div>
        <div className="form-group">
          <label>Email</label>
          <input {...register('email', { required: 'Email is required' })} />
          <p className="error-message">{errors.email?.message}</p>
        </div>
        <div className="form-group">
          <label>Address</label>
          <input {...register('address', { required: 'Address is required' })} />
          <p className="error-message">{errors.address?.message}</p>
        </div>
        <div className="form-group">
          <label>Card Number</label>
          <input {...register('cardNumber', { required: 'Card number is required' })} />
          <p className="error-message">{errors.cardNumber?.message}</p>
        </div>
        <div className="form-group">
          <label>Expiry Date</label>
          <input {...register('expiryDate', { required: 'Expiry date is required' })} />
          <p className="error-message">{errors.expiryDate?.message}</p>
        </div>
        <div className="form-group">
          <label>CVV</label>
          <input {...register('cvv', { required: 'CVV is required' })} />
          <p className="error-message">{errors.cvv?.message}</p>
        </div>
        <button type="submit" className="submit-button">Complete Purchase</button>
      </form>
    </div>
  );
};

export default Checkout;
