import styles from "./home.module.css";

import photo1 from "../../assets/home5.png";
import photo2 from "../../assets/home6.png";
import photo3 from "../../assets/home7.jpg";
import { useNavigate } from "react-router";

const FourthSection = () => {
  const navigate = useNavigate();

  return (
    <div className={`${styles.about} container sections-padding`}>
      <div
        className={styles.text}
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500">
        <p className="paragraph">About</p>
        <h2>
        FITNESS.SCIENCE.YOU
          <br />
          Thats what You Get
        </h2>
        <p>
        At EVOLVE FIT ACADEMY we strive to build a successful and bright future based on International Scientific Knowledge to level up you to highest levels.

        </p>
        <div className={styles.text_one}>
          <div>
            <h3>Educate</h3>
            <p>
            Educates and trains instructors to meet global standards.
            </p>
          </div>
          <img src={photo1} alt="Photo one" />
        </div>
        <div className={styles.text_two}>
          <img src={photo2} alt="Photo two" />
          <div>
            <h3>Evaluate and Cultivate</h3>
            <p>Evaluates, certifies, and facilitates the employment of trainees ⁠and Cultivates a sustainable organization through ongoing innovation.
 </p>
          </div>
        </div>
      </div>
      <div
        className={styles["img-container"]}
        data-aos="fade-up"
        data-aos-duration="3000">
        <img src={photo3} alt="Man Exercise" />
        {/* <button onClick={() => navigate("/schedule")}>Get Started</button> */}
      </div>
    </div>
  );
};

export default FourthSection;
